import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteUserComponent } from './popups/delete-user/delete-user.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonButtonComponent } from '../utilities/common-button/common-button.component'
import { UserTableComponent } from '../user-management/common/user-table/user-table.component';
import { CheckboxComponent } from '../utilities/checkbox/checkbox.component';
import { UserDetailsComponent } from '../user-management/common/user-details/user-details.component';
import { AddUserComponent } from '../user-management/common/add-user/add-user.component';
import { InputFiledComponent } from '../utilities/input-filed/input-filed.component';
import { DropdownComponent } from '../utilities/dropdown/dropdown.component';
import { TextAreaComponent } from '../create-package/common/text-area/text-area.component';
import { CommonPackageHeaderComponent } from '../create-package/common/common-package-header/common-package-header.component';
import { CommonArticlesCardComponent } from '../utilities/common-articles-card/common-articles-card.component';
import { CommonStepsCardComponent } from '../utilities/common-steps-card/common-steps-card.component';
import { BulkListCommonComponent } from '../user-management/common/bulk-list-common/bulk-list-common.component';
import { BulkUploadListComponent } from '../user-management/common/bulk-upload-list/bulk-upload-list.component';
import { BulkUploadUsersComponent } from '../user-management/common/bulk-upload-users/bulk-upload-users.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { BlockchainTransferCardComponent } from '../create-package/common/blockchain-transfer-card/blockchain-transfer-card.component';
import { BlockchainKeyGenerarteAndVerifyComponent } from '../onboarding-dashboard/blockchain-key/blockchain-key-generarte-and-verify/blockchain-key-generarte-and-verify.component';
import { OffcanvasHeaderComponent } from '../utilities/offcanvas-header/offcanvas-header.component';
import { FilterOffcanvasComponent } from '../package-listing/common/filter-offcanvas/filter-offcanvas.component';
import { RadioButtonComponent } from '../create-package/common/radio-button/radio-button.component';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationPopupComponent } from './popups/confirmation-popup/confirmation-popup.component';
import { DropdownSearchComponent } from '../create-package/common/dropdown-search/dropdown-search.component';
import { RadiobuttonComponent } from '../utilities/radiobutton/radiobutton.component';
import { PackageFileCardComponent } from '../utilities/package-file-card/package-file-card.component';
import { DescriptionFieldComponent } from '../document-preview/common/description-field/description-field.component';
import { OrganisationalDetailsComponent } from '../global-setting/organisational-details/organisational-details.component';
import { UppercaseInputDirective } from '../admin/sdex/directives/uppercase-input.directive';
import { NotificationCardComponent } from '../Notification/common/notification-card/notification-card.component';
import { NotificationCardRowComponent } from '../Notification/common/notification-card-row/notification-card-row.component';
import { GlobalSettingHeaderComponent } from '../global-setting/global-setting-header/global-setting-header.component';
import { ZoomSliderComponent } from '../utilities/zoom-slider/zoom-slider.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CommonBreadcrumComponent } from '../utilities/common-breadcrum/common-breadcrum.component';
import { ListPageHeaderComponent } from './components/list-page-header/list-page-header.component';
import { UpdateCompanySettingsComponent } from '../admin/company/company-settings/update-company-settings/update-company-settings.component';
import { ToastComponent } from '../roles-permisions/common/toast/toast.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectDropdownComponent } from '../utilities/multi-select-dropdown/multi-select-dropdown.component';
import { AlphabetsDirective } from '../utilities/directives/alphabet.directive';
import { AutocompleteComponent } from '../utilities/autocomplete/autocomplete.component';
import { ErrorToasterComponent } from './toasts/error-toaster/error-toaster.component';
import { SharedAccessModule } from '../shared-access/shared-access.module';
import { CommonIconComponent } from '../utilities/common-icon/common-icon.component';
import { CommonToggleSwitchComponent } from '../utilities/common-toggle-switch/common-toggle-switch.component';
import { MfaComponent } from '../user-settings/mfa/mfa.component';
import { LookupComponent } from '../lookup/lookup.component';
import { AddLookupComponent } from "../lookup/add-lookup/add-lookup.component";
import { DocumentTypeListComponent } from '../lookup/document-type-list/document-type-list.component';
import { ModuleListComponent } from '../lookup/module-list/module-list.component';
import { AddDocumentTypeComponent } from '../lookup/add-document-type/add-document-type.component';
import { AddModuleTypeComponent } from '../lookup/add-module-type/add-module-type.component';
import { CustomRegexComponent } from './components/custom-regex/custom-regex.component';
import { FileExtensionsComponent } from './components/file-extensions/file-extensions.component';
import { SentMailComponent } from './components/sent-mail/sent-mail.component';
import { BreadCrumsComponent } from './components/bread-crums/bread-crums.component';
import { PieChartComponent } from '../utilities/pie-chart/pie-chart.component';
import { LineChartComponent } from '../utilities/line-chart/line-chart.component';
import { CustomDatePickerComponent } from '../utilities/custom-date-picker/custom-date-picker.component';
import { AutoSearchDropdownComponent } from '../utilities/auto-search-dropdown/auto-search-dropdown.component';
import { ClmsContractTypesComponent } from '../lookup/clms-contract-types/clms-contract-types.component';
import { ClmsAddContractTypeComponent } from '../lookup/clms-add-contract-type/clms-add-contract-type.component';
import { ContactsComponent } from '../lookup/contacts/contacts.component';
import { EditContactsComponent } from '../lookup/edit-contacts/edit-contacts.component';
import { EsignPositonComponent } from '../utilities/esign-positon/esign-positon.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BarChartComponent } from '../utilities/bar-chart/bar-chart.component';
import { LicenceListComponent } from '../user-management/common/licence-list/licence-list.component';
import { StorageFormatPipe } from './pipes/storageFormator.pipe';

@NgModule({
    declarations: [
        DeleteUserComponent,
        FiltersComponent,
        CommonButtonComponent,
        CommonIconComponent,
        UserTableComponent,
        CheckboxComponent,
        UserDetailsComponent,
        AddUserComponent,
        InputFiledComponent,
        DropdownComponent,
        TextAreaComponent,
        CommonPackageHeaderComponent,
        CommonStepsCardComponent,
        CommonArticlesCardComponent,
        BulkUploadUsersComponent,
        BulkUploadListComponent,
        BlockchainKeyGenerarteAndVerifyComponent,
        BulkListCommonComponent,
        BlockchainTransferCardComponent,
        OffcanvasHeaderComponent,
        FilterOffcanvasComponent,
        RadioButtonComponent,
        OrganisationalDetailsComponent,
        ConfirmationPopupComponent,
        DropdownSearchComponent,
        RadiobuttonComponent,
        GlobalSettingHeaderComponent,
        PackageFileCardComponent,
        DescriptionFieldComponent,
        NotificationCardComponent,
        NotificationCardRowComponent,
        ZoomSliderComponent,
        UppercaseInputDirective,
        PaginatorComponent,
        CommonBreadcrumComponent,
        ListPageHeaderComponent,
        UpdateCompanySettingsComponent,
        ToastComponent,
        MultiSelectDropdownComponent,
        AlphabetsDirective,
        AutocompleteComponent,
        ErrorToasterComponent,
        CommonToggleSwitchComponent,
        MfaComponent,
        LookupComponent,
        AddLookupComponent,
        ModuleListComponent,
        AddModuleTypeComponent,
        DocumentTypeListComponent,
        ModuleListComponent,
        AddDocumentTypeComponent,
        CustomRegexComponent,
        FileExtensionsComponent,
        SentMailComponent,
        BreadCrumsComponent,
        PieChartComponent,
        LineChartComponent,
        CustomDatePickerComponent,
        AutoSearchDropdownComponent,
        ClmsContractTypesComponent,
        ClmsAddContractTypeComponent,
        ContactsComponent,
        EditContactsComponent,
        EsignPositonComponent,
        BarChartComponent,
        LicenceListComponent,
        StorageFormatPipe
    ],
    exports: [
        DeleteUserComponent,
        FiltersComponent,
        CommonButtonComponent,
        CommonIconComponent,
        UserTableComponent,
        CheckboxComponent,
        UserDetailsComponent,
        AddUserComponent,
        InputFiledComponent,
        DropdownComponent,
        TextAreaComponent,
        CommonPackageHeaderComponent,
        CommonStepsCardComponent,
        CommonArticlesCardComponent,
        BlockchainKeyGenerarteAndVerifyComponent,
        BulkUploadUsersComponent,
        BulkUploadListComponent,
        BulkListCommonComponent,
        OverlayPanelModule,
        BlockchainTransferCardComponent,
        OffcanvasHeaderComponent,
        FilterOffcanvasComponent,
        RadioButtonComponent,
        DropdownSearchComponent,
        RadiobuttonComponent,
        PackageFileCardComponent,
        GlobalSettingHeaderComponent,
        DescriptionFieldComponent,
        OrganisationalDetailsComponent,
        NotificationCardComponent,
        NotificationCardRowComponent,
        ZoomSliderComponent,
        UppercaseInputDirective,
        PaginatorComponent,
        CommonBreadcrumComponent,
        ListPageHeaderComponent,
        UpdateCompanySettingsComponent,
        ToastComponent,
        MultiSelectDropdownComponent,
        AlphabetsDirective,
        AutocompleteComponent,
        ErrorToasterComponent,
        CommonToggleSwitchComponent,
        MfaComponent,
        LookupComponent,
        AddLookupComponent,
        ModuleListComponent,
        AddModuleTypeComponent,
        DocumentTypeListComponent,
        ModuleListComponent,
        AddDocumentTypeComponent,
        CustomRegexComponent,
        FileExtensionsComponent,
        SentMailComponent,
        BreadCrumsComponent,
        PieChartComponent,
        LineChartComponent,
        CustomDatePickerComponent,
        AutoSearchDropdownComponent,
        ClmsContractTypesComponent,
        ClmsAddContractTypeComponent,
        ContactsComponent,
        EditContactsComponent,
        NgbModule,
        EsignPositonComponent,
        BarChartComponent,
        PdfViewerModule,
        StorageFormatPipe
    ],
    imports: [
        NgbModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        CalendarModule,
        FontAwesomeModule,
        OverlayPanelModule,
        PaginatorModule,
        NgCircleProgressModule.forRoot(),
        SharedAccessModule,
        PdfViewerModule
    ],
    providers: [
        DatePipe,
        StorageFormatPipe
    ]
})
export class SharedModule {}
