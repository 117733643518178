import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RegExpPatterns } from 'src/app/share/enums/regex-pattern.enum';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { AuthService } from '../auth/auth.service';
import * as CryptoJS from 'crypto-js';
import { userSelfDetails } from 'src/app/user-storage';
import { SdexAdminService } from 'src/app/share/services/sdex/sdex-admin.service';
import { environment } from 'src/environments/environment';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { DOCUMENT } from '@angular/common';
import { UserSettingService } from 'src/app/share/services/User-Setting/user-setting.service';
import { CompanyServiceService } from 'src/app/share/services/company-service/company-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {

  loginForm     !: FormGroup;
  hide = true;
  signInLoader: boolean = false;
  typeBtn: boolean;
  errorMessage  !: string;
  companyDetails: any;
  errorLogin: any;
  totpPendingId: any;
  isGoogleSign: boolean;
  isMicrosoftSign: boolean;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  customUrldata: any
  documentVaultStatus: boolean = false;
  isChangePassword: boolean = false;
  isGMSign: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private storageService: StorageService,
    private sdexAdminService: SdexAdminService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private userSettingService: UserSettingService,
    private companyService: CompanyServiceService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [Validators.required, Validators.pattern(RegExpPatterns.EmailPattern)],
      ],
      password: ['', [Validators.required, Validators.minLength(6)]],
      totp: ['', [Validators.maxLength(6)]]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['token']) {
        let data = JSON.parse(atob(params['token']))
        this.storageService.setDataToLocalStorage(
          StorageKeys.ACCESS_TOKEN,
          data.token
        );
        this.getSelfDetails();
        this.isGoogleSign = false;
        this.isGMSign = true;
      }
      else if (params['failure']) {
        this.errorLogin = params['failure'];
        this.isGoogleSign = false;
      }
    });
    if (localStorage.getItem('token')) {
      this.getSelfDetails();
    }

    this.authService.getOrgLoginData()?.subscribe({
      next: (response: any) => {
        this.customUrldata = response.data;
        this.changeFavicon(this.customUrldata?.favIcon)
      },error:(err)=>{

      }
    })
  }

  changeFavicon(newIconPath: string) {
    const favicon = this.document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
        favicon.href = newIconPath;
    }
}

  verifyTotp(): void {
    if (this.signInLoader) {
      return;
    }
    this.signInLoader = true;
    const credentials = {
      totp: this.loginForm.value.totp,
      totpPendingId: this.totpPendingId,
    }
    this.authService.verifyTotp(credentials)?.subscribe({
      next: (response: any) => {
        if (response?.data?.token) {
          this.storageService.setDataToLocalStorage(
            StorageKeys.ACCESS_TOKEN,
            response.data.token
          );
          this.storageService.setDataToLocalStorage(
            StorageKeys.COMPANY_ID,
            response.data.company
          );
          this.getSelfDetails();
        }
        this.signInLoader = false;
      },

      error: (error) => {
        this.errorLogin = error?.error?.error?.message;
        this.signInLoader = false;
      }
    });
  }

  onLogin(): void {
    this.totpPendingId = undefined;
    if (this.signInLoader) {
      return;
    }
    this.signInLoader = true;
    const credentials = {
      username: this.loginForm.value.username,
      password: CryptoJS.SHA256(this.loginForm.value.password).toString(CryptoJS.enc.Hex),
    }

    this.authService.login(credentials,environment.envType)?.subscribe({
      next: (response: any) => {
        if (response?.data?.totpPendingId) {
          this.totpPendingId = response?.data?.totpPendingId;
        } else if (response?.data?.token) {
          this.storageService.setDataToLocalStorage(
            StorageKeys.ACCESS_TOKEN,
            response.data.token
          );
          this.storageService.setDataToLocalStorage(
            StorageKeys.COMPANY_ID,
            response.data.company
          );
          this.getSelfDetails();
          this.getCompanyPrefs();
        }
        this.signInLoader = false;
      },

      error: (error) => {
        this.isChangePassword = error?.error?.error?.data?.changePassword;
        this.errorLogin = error?.error?.error?.message;
        this.signInLoader = false;
      }
    });
  }

  changePassword() {
    this.router.navigate(['/forgot-password'], { queryParams: { isChangePassword: this.storageService.encrypt('true') } });
  }

  getSelfDetails() {
    this.authService.getSelfDetails()?.subscribe({
      next: (res) => {
        if (res.data.status === 'Active') {
          userSelfDetails(this.authService, this.sdexAdminService, this.storageService, this.userSettingService, this.envType, (selfDetails: any) => {
            this.checkDamsNavigation(selfDetails);
            this.getuserlicense();
          });
          if (this.envType !== this.appType.dams) {
            this.sdexClmsDashboard(res);
          }
        }
        else if (res.data.status === 'Locked') {
          this.errorLogin = 'User has been locked';
        } else {
          this.errorLogin = 'Company not activated';
        }
      },
      error: (error) => {
        this.errorLogin = error?.error?.error?.message;
      }
    });
  }

  checkDamsNavigation(selfDetails: any){
    if (selfDetails) {
      if (this.envType === this.appType.dams) {
        this.damsDashboard(selfDetails);
      }
      if (this.envType != this.appType.dams) {
        location.reload();
      }
    }
  }

  sdexClmsDashboard(res: any) {
    if (this.envType != this.appType.clms) {
      if (res.data.enrollmentType == 'Admin') {
        this.router.navigate(['/admin/sdex/company']);
      }
      else if (this.envType === this.appType.sdex) {
        this.router.navigate(['/admin/dashboard']);
      }
    }
    if (this.envType == this.appType.clms) {
      if (res.data.enrollmentType == 'Admin') {
        this.router.navigate(['/admin/sdex/company']);
      } else if(!res.data?.company && res.data?.guestCompanies.length) {
        this.router.navigate(['/contracts']);
      } else{
        this.router.navigate(['/admin/dashboard']);
      }
    }
  }

  damsDashboard(selfDetails: any) {
    let hasDocumentAccess = this.authService.documentVaultAccess();
    let hasCompanyAccess = this.authService.companyAccess();
    let esignPrefDisabled = selfDetails?.disabledPermissions?.includes('e-sign-details');
    if (selfDetails.enrollmentType == 'Admin') {
      if(hasCompanyAccess){
        this.router.navigate(['/admin/sdex/company']);
      }else{
        this.router.navigate(['/admin/dashboard']);
      }
      return;
    }
    if(selfDetails?.documentVault?.isExists){
      if(!hasDocumentAccess){
        this.router.navigate(['/admin/dashboard']);
      }else if( esignPrefDisabled || ( !esignPrefDisabled && selfDetails?.eSignSelfLink)){
        this.router.navigate(['dams-dashboard']);
      } else if(!esignPrefDisabled && selfDetails?.eSignSelfLink === undefined){
        this.router.navigate(['/admin/dashboard']);
      }else{
        this.router.navigate(['/admin/dashboard']);
      }
    }else{
      this.router.navigate(['/admin/dashboard']);
    }
  }

  googleSignIn(provider: string) {
    if (provider === 'google') {
      this.isGoogleSign = true;
    } else {
      this.isMicrosoftSign = true;
    }
    window.location.href = `${environment.API_URL}/v1/oauth/` + provider + "?webEnv=" + (environment.envType || '').toLowerCase();
  }

  getCompanyPrefs(){
    this.userSettingService.getCompanyPrefs().subscribe({
      next:(res: any)=>{
        if(res.data){
          this.storageService.setDataToLocalStorage(StorageKeys.PREFERENCES,res.data);
        }
      }
    })
  }

  getuserlicense() {
    let self = this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
    if (self?._id) {
      this.companyService.getuserlicense(self?._id)?.subscribe({
        next: (res: any) => {
          if (res?.data?.license) {
            this.storageService.setDataToLocalStorage(StorageKeys.SCANER_LICENCE, res.data.license)
          }
        }, error: (error) => {
        }
      });
    }
  }

  inputEvent(e: any){
    if(this.errorLogin){
      this.errorLogin = '';
    }
  }
  protected readonly faEye = faEye;
  protected readonly faEyeSlash = faEyeSlash;

}
