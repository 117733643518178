export const environment = {
  envType: 'DAMS',
  production: false,
  rolesDisabled: false,
  scanDisabled: true,
  API_URL: `https://api-dams.pre-prod.sdex.in`,
  API_ESIGN_URL: `https://api.internal.sdex.in/public-esign`,
  IMAGE_URL: `https://axivise-images.s3.ap-south-1.amazonaws.com/app/`,
  PRIVATE_KEY : 'EyVyCOlFdINkWtRd7HI6PWKyXOIhuTfS',
  Dynamsoft: {
    resourcesPath: 'assets/dwt-resources',
    uploadTargetURL: ''
  }
};
