<div class="chart-container">
    <p *ngIf="data?.length > 0" class="chart-title">Storage Consumption</p>
    <div style="width: 700px; height: 300px;">
        <canvas id="canvas">{{ chart }}</canvas>
    </div>
</div>

<div class="custom-table-list-row-main" *ngIf="isBarChartLoder">
    <div class="custom-col-width-100 text-danger d-flex justify-content-center">
        <app-common-icon [loadingIcon]="true"></app-common-icon>
    </div>
</div>

<div class="color-red chart-records" *ngIf="(!data || data.length === 0) && !isBarChartLoder">
    <p>The Storage is currently Empty</p>
</div>