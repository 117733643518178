import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'storageFormat' })
export class StorageFormatPipe implements PipeTransform {
    transform(size: number): string {
        if (size) {
            if (size < 1024) {
                return size + ' Bytes';
            } else if (size < 1024 * 1024) {
                return (size / 1024).toFixed(2) + ' KB';
            } else if (size < 1024 * 1024 * 1024) {
                return (size / (1024 * 1024)).toFixed(2) + ' MB';
            } else {
                return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
            }
        } else {
            return '0 GB';
        }
    }
}
