<div class="modal-header zindex-modal">
    <h4 class="modal-title" id="modal-basic-title">
       Confirmation
    </h4>
    <div (click)="activeModal.close(false)" class="close"><img
            src="../../../../assets/Images/organisation-structure/Cross.svg" alt="cross icon"></div>
</div>
<div class="modal-body">
    <div>
        <h4 [ngClass]="[lableName === 'Yes' ? 'text-danger' : '']">{{message}} ?</h4>

        <div class="dropdown-container" *ngIf="showDropdown">
            <select  class="dropdownOptions" [formControl]="dropDownController">
                <option value="" disabled selected>Select an option</option>
                <option  *ngFor="let item of dropdownOptions" [value]="item.value">{{ item.label }}</option>
                <option [value]="'Other'" >Other</option>
            </select>
        </div>

        <app-description-field *ngIf="isConfirmationMessage || dropDownController.value === 'Other'" class="pt-3"
        placeholder="Reason"
        [control]="reason"></app-description-field>


    </div>
</div>
<div class="modal-footer">
    <div class="text-right">
        <div class="d-flex justify-content-end">
            <app-common-button class="m-1" buttonClass="smallbutton" [buttonLabel]="lableCancel || 'Cancel'"
                (click)="activeModal.close(false)">
            </app-common-button>
            <app-common-button class="m-1" [disabled]="(isConfirmationMessageRequired || isDropdownSelectionReqired) && !(reason.value || dropDownController.value)" buttonClass="smallFilledButton" buttonLabel={{lableName}}
                (click)="successClose()">
            </app-common-button>
        </div>
    </div>
</div>