import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})

export class ConfirmationPopupComponent {
  @Input() message: any;
  @Input() header: any;
  @Input() lableName: any;
  @Input() lableCancel: any;
  @Input() isConfirmationMessage: boolean = false;
  @Input() isConfirmationMessageRequired: boolean = false;
  @Input() showDropdown: boolean = false;
  @Input() isDropdownSelectionReqired : boolean = false
  @Input() dropdownOptions: { label: string, value: any }[] = [];
  @Output() newFolderName: EventEmitter<any> = new EventEmitter();

  reason: FormControl<any> = new FormControl('');

  dropDownController: FormControl<any> = new FormControl('');

  constructor(
    public activeModal: NgbActiveModal) { }

  successClose() {
    if (this.isConfirmationMessage) {
      this.activeModal.close({
        reason: this.reason.value,
        isClose: true
      })
    } else if (this.showDropdown) {
      this.activeModal.close({
        reason: this.dropDownController.value === 'Other' ? this.reason.value : this.dropDownController.value,
        isClose: true
      })
    }
    else {
      this.activeModal.close(true)
    }

  }
}
