
export const RouteConstants = {
  Home: '',
  Login: 'login',
  AdminTemplatesList: 'admin-templates-list',
  CompanyTemplatesList: 'company-templates-list',
  LrTemplateGeneration: 'lr-generation',
  GeneretedLrList: 'generated-lrs',
  LrSettings: 'lr-settings',
  LrNumberSetting: 'lr-number-setting',
  SdexUsers: 'users',
  Notifications: 'notification',
  SubCompanyUsers: 'sub-company-users',
  ViewCompanyPreference: 'company-preference',
  ViewContactList: 'contact-list',
  SdexCompanyList: 'sdex/company',
  CompanyList: 'company',
  CompanySettings: 'company-settings',
  SdexViewCompanyDetails: 'sdex-view-company',
  ViewCompanyDetails: 'view-company',
  DocumentValut: 'document-vault',
  Package: 'packages',
  IncommingPackages: 'incoming',
  OutgoingPackages: 'outgoing',
  DraftPackages: 'draft',
  PackageDetails: 'package-details',
  CreatePackage: 'create-new-package',
  PackageApproval: 'package-approval',
  UserSetting: 'user-setting',
  Roles: 'roles',
  NotifyUsers: 'notify-users',
  Dashboard: 'dashboard',
  OrganizationStructure: 'organization-structure',
  EwayBill: 'eway-bill/credentials',
  CreateCredentials: 'createcredentials',
  Iba: 'iba',
  Einvoice: 'einvoice',
  Mfa: 'mfa',
  Lookup: 'lookup',
  Preferences: 'preferences',
  Contract: 'contracts',
  Generate: 'generate',
  EditContract: 'edit-contract',
  ContractsTemplate: 'contractsTemplate',
  ContactsComponent: 'contacts',
  ExistingContracts: 'existingcontracts',
  ViewContract :  'view-contract',
  DamsDashboard : 'dams-dashboard',
  ContractDashboard : 'clms-dashboard',
  CmsPreferences : 'cms-preference',
  FileBulkUpload : 'document-vault/file-bulkupload',
  BulkUploadList : 'document-vault/file-bulkupload/list',
  PublicEsignComponent : 'public-esign',
  EKycSearchList : 'ekyc-list',
  StudentList : 'student-list',
  StudentFile : 'student-file',
  SSCCertificates: 'ssc-certificates',
  MaterailRegister : 'material',
  ViewRegister :  'view-register',
  OutboundView : 'outbound-view',
  ScrapView    :'scrap-view',
  Shipment : 'shipment',
  BIECertificates: 'bie-certificates',
  ApiKey : "api-key"
};

export const DashBoardRouteConst = [
  {
    label: 'Verify Identity',
    route: ''
  },
  {
    label: 'Generate Key',
    route: ''
  },
  {
    label: 'Generated',
    route: ''
  },
  {
    label: 'Add Network',
    route: 'admin/contact-list',
    open: true
  },
  {
    label: 'View Networks',
    route: 'admin/contact-list',
    open: false
  },
  {
    label: 'Create Org.Structure',
    route: 'organization-structure',
    open: true
  },
  {
    label: 'View Org.Structure',
    route: 'organization-structure',
    open: false
  },
  {
    label: 'Define Role',
    route: 'roles/role-list',
    open: true
  },
  {
    label: 'View Roles',
    route: 'roles/role-list',
    open: false
  },
  {
    label: 'Create Package',
    route: 'packages/create-new-package',
    open: true
  },
  {
    label: 'View Packages',
    route: 'packages/incoming',
    open: false
  },
  {
    label: 'Create User',
    route: 'admin/sub-company-users',
    open: true
  },
  {
    label: 'View Users',
    route: 'admin/sub-company-users',
    open: false
  },
  {
    label: 'Suppliers',
    route: 'shipment/suppliers',
    open: false
  },
  {
    label: 'Transportes',
    route: 'shipment/transportes',
    open: false
  },
  {
    label: 'Scrap Buyers',
    route: 'shipment/scrap-buyers',
    open: false
  },
  {
    label: 'Customers',
    route: 'shipment/customers',
    open: false
  }
];

