import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyServiceService {
  constructor(private http: HttpClient) {}
  getToCompanyList(params?: any) {
    return this.http.get(`${environment.API_URL}/v1/company`, {
      params,
    });

  }
  activateCompany(companyId: any, data: any) {
    let url = environment.API_URL + '/v1/company/' + companyId + '/' + data+'?envType='+environment.envType;
    return this.http.put(url, {});
  }
  getAllCompanyList(name: string) {
    let params = new HttpParams();
    params = params.append('name', name);
    params = params.append('skip', 1);
    params = params.append('limit', 100);
    let url = environment.API_URL + '/v1/company';
    return this.http.get(url, { params: params });
  }
  createRootCompany(payload: any) {
    let url = environment.API_URL + '/v1/company/root-company';
    return this.http.post(url, payload);
  }
  createSubCompany(payload: any) {
    let url = environment.API_URL + '/v1/company/sub-company';
    return this.http.post(url, payload);
  }
  getCompanyDataById(companyId: any) {
    let url = environment.API_URL + `/v1/company/${companyId}`;
    return this.http.get(url);
  }

  getAllTemplateDetails() {
    let url = environment.API_URL + '/v1/lr-templates';
    return this.http.get(url);
  }

  getAllTemplatesByCompanyId(companyId: any) {
    let url =
      environment.API_URL + '/v1/company-lr-template/company/' + companyId;
    return this.http.get(url);
  }

  // cms
  getContractTemplateDetails(){
    let url =
    environment.API_URL + '/v1/cms-template';
  return this.http.get(url);

  }

  getContractSettings(_id: any){
    let url =
    environment.API_URL + '/v1/cms-template-settings/' + _id;
  return this.http.get(url);

  }

  contractFormat( _id: any,payload: any) {
    let url = environment.API_URL + `/v1/cms-template-settings/` + _id;
    return this.http.post(url, payload);
  }

  assignTemplateToCompany(payload: any) {
    let url = environment.API_URL + '/v1/company-lr-template';
    return this.http.post(url, payload);
  }
  createOrgStruct(payload: any) {
    let url =
      environment.API_URL + '/v1/company-structure/' + payload.rootCompany;
    return this.http.post(url, payload);
  }
  getOrganizationStructureById(id: any) {
    let url = environment.API_URL + '/v1/company-structure';
    if (id) {
      url += '/' + id;
    }
    return this.http.get(url);
  }
  editInvite(id: any, data: any) {
    let url = environment.API_URL + '/v1/contacts/edit/' + id;
    return this.http.put(url, data);
  }

  deleteRootCompany(id: any) {
    let url = environment.API_URL + `/v1/company/root-company/${id}`;
    return this.http.delete(url);
  }

  getAreaBranchCompany(parentId: any, level: any) {
    let params = new HttpParams();
    params = params.append('level', level);
    let url = environment.API_URL + '/v1/company/getchild/' + parentId;
    return this.http.get(url, { params: params });
  }

  getDataUploadFile(payload: any, companyId: any) {
    let url = environment.API_URL + `/v1/company/companyDocument/` + companyId;
    return this.http.put(url, payload);
  }
  uploadFile(payload: any) {
    let url = environment.API_URL + '/v1/digilocker/private-file/upload';
    return this.http.post(url, payload);
  }

  createUser(payload: any) {
    let url = environment.API_URL + `/v1/user/add/sub-company-user?envType=${environment.envType}`;
    return this.http.post(url, payload);
  }

  editUser(payload : any, userId : string) {
    let url = `${environment.API_URL}/v1/user/info/${userId}`;
    return this.http.put(url, payload);
  }

  addCompanyUser(payload : any):Observable<any>{
    return this.http.post(`${environment.API_URL}/v1/user`,payload)
  }

  deactivateNetwork(networkId : string, status: string) {
    let url = `${environment.API_URL}/v1/contacts/${status}/${networkId}`;
    return this.http.put(url,'');
  }

  getCompaniesCount(params?: any) {
    let url = environment.API_URL + '/v1/company/companies-count';
    return this.http.get(url,{
      params
    });
  }

  deActivateCompany(id: any) {
    let url = environment.API_URL + `/v1/company/${id}/lock`;
    return this.http.put(url,'');
  }

  unLockCompany(id: any) {
    let url = environment.API_URL + `/v1/company/${id}/unlock`;
    return this.http.put(url,'');
  }

  kycDetails(id: any) {
    let url = environment.API_URL + `/v1/bg-process/${id}`;
    return this.http.get(url);
  }

  addKycDetails(payload : any,contactId: any){
    let url = environment.API_URL + `/v1/contacts/kyc/${contactId}`;
    return this.http.post(url, payload);
  }

  addCompanyLicense(payload : any){
    let url = environment.API_URL + `/v1/company-licenses`;
    return this.http.post(url, payload);
  }

  updateCompanyLicense(payload : any, id:any ){
    let url = environment.API_URL + `/v1/company-licenses/update-company-license/${id}`;
    return this.http.put(url, payload);
  }

  getCompanyLicenses(rootCompany: string){
    let url = environment.API_URL + `/v1/company-licenses/list/${rootCompany}`;
    return this.http.get(url);
  }

  getuserlicense(userid: any){
    let url = environment.API_URL + `/v1/licenses-user`;
    return this.http.get(url);
  }

  getLicenses(companyId?: any): Observable<any> {
    let url = `${environment.API_URL}/v1/company-licenses`;
    if (companyId) {
      url += `?availableTo=${companyId}`;
    }
    return this.http.get(url);
  }

  getAssignLicenses(companyId?: any): Observable<any> {
    let url = `${environment.API_URL}/v1/company-licenses?assignTo=${companyId}`;
    return this.http.get(url);
  }

  onSaveLicense(licenseId: any,payload : any): Observable<any> {
    let url = `${environment.API_URL}/v1/company-licenses/${licenseId}/assign-to`;
    return this.http.put(url,payload);
  }

  getAssignUserLicenses(): Observable<any> {
    let url = `${environment.API_URL}/v1/licenses-user/list`;
    return this.http.get(url);
  }

  assignUserLicense(userId?: any): Observable<any> {
    let url = `${environment.API_URL}/v1/licenses-user`;
    return this.http.post(url, {userId});
  }
}
