import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faCircleCheck, faKey } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcceptRejectConfirmationModalComponent } from '../../../admin/company/accept-reject-confirmation-modal/accept-reject-confirmation-modal.component';
import { StorageService } from 'src/app/share/services/storage-service/storage.service';
import { StorageKeys } from 'src/app/share/enums/storage.enum';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CompanyServiceService } from 'src/app/share/services/company-service/company-service.service';
import { environment } from 'src/environments/environment';
import { APPTYPES } from 'src/app/share/constants/common-constants';
import { LicenceListComponent } from '../licence-list/licence-list.component';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnChanges, OnInit{

  @Input() userData     : any;
  @Output() userEvent   = new EventEmitter<any>();
  @Output() branchEvent = new EventEmitter<any>();
  maxFilelength         : number  = 25;
  isAdmin               : boolean = false;
  userRoleData          : any     = [];
  companyData           : any     = [];
  selfDetails           : any;
  companyLicences       : any;
  userLicences: any = [];
  selectedLicence       : any;
  isToaster             : boolean = false;
  isErrorToaster        : boolean = false;
  message               : any;
  loderIcon             : boolean = false;
  envType = environment?.envType || 'SDEX';
  appType = APPTYPES;
  @ViewChild('Branch') branchpOverlayPanel           : OverlayPanel;
  @ViewChild('Company') companypOverlayPanel         : OverlayPanel;

  ngOnInit(): void{
    if (this.envType == this.appType.dams) {
      this.getAssignUserLicenses();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userData'].currentValue) {
      this.selfDetails =
        this.storageService.getDataFromLocalStorage(StorageKeys.SELF_DETAILS);
      if (this.selfDetails.enrollmentType === "Admin") {
        this.isAdmin = true;
      }
      this.getRolesAndBranches();
    }
  }

  constructor(private modalService: NgbModal,
    private storageService: StorageService,
    private companyService: CompanyServiceService
    ) { }

  getRolesAndBranches() {
    for (let i = 0; i < (this.userData || []).length; i++) {
      this.userData[i].branches = [];
      this.userData[i].branchRoles = [];
      this.userData[i].assignedTo?.map((item: any) => {
        if (item.company?.name) {
          this.userData[i].branches.push(item.company?.name);
        }
        if (item.isAdmin) {
          this.userData[i].branchRoles.push('Admin');
        } else {
          item?.roles?.map((y: any) => {
            if (y?.name) {
              this.userData[i].branchRoles.push(y.name);
            }
          });
        }
      });
      this.userData[i].company = this.userData[i].branches.join(', ');
      this.userData[i].roles = this.userData[i].branchRoles.join(', ');
    }
  }

  editUser(value: any, user: any) {
    this.userEvent.emit({ event: value, user });
  }

  /*added fa icons */
  protected readonly faEye = faEye;
  protected readonly faBan = faBan;
  protected readonly faLock = faLock;
  protected readonly faCircleCheck = faCircleCheck;
  protected readonly faKey = faKey;

  /* to activate company */
  activateCompany(userData: any, isActive: any) {
    let dialogRef = this.modalService.open(
      AcceptRejectConfirmationModalComponent,
      {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      }
    );
    if(dialogRef){
    dialogRef.componentInstance.companyActivated = isActive;
    dialogRef.componentInstance.message = `Do You Want To ${isActive} "${userData?.name}" ? `
    dialogRef.result.then(
      (result) => {
        if (result?.status) {
          if (isActive == 'Activate') {
            this.userData
              ?.activateCompany(userData._id, 'activate');
          } else if (isActive === 'Deactivate') {
            this.userData?.deleteRootCompany(userData._id).subscribe((res: any) => {

            });
          }
        }
      }
    );
  }
}
  largeFileName(fileName: string) {
    return fileName.substring(0, this.maxFilelength);
  }
  getTooltipEamilName(email: any) {
    return email.length >= 16 ? email : '';
  }
  getTooltipName(name: any) {
    return name.length >= 10 ? name : '';
  }

  openPanel(type: string, tableData: any, event: any) {
    if (type === "Branch") {
      if (tableData.roles.length >= 21) {
        this.branchpOverlayPanel?.show(event);
        if (tableData.roles.length > 0) {
          this.userRoleData = tableData.roles.split(",");
        }
      }
    } else if (type === "Company") {
      if (tableData.company.length >= 21) {
        this.companypOverlayPanel?.show(event);
        if (tableData.company.length > 0) {
          this.companyData = tableData.company.split(",");
        }
      }
    }

  }

  closePanael(type: string) {
    if (type === "Branch") {
      this.branchpOverlayPanel?.hide();
    } else {
      this.companypOverlayPanel?.hide();
    }
  }

  addBranch(userdata: any){
    this.branchEvent.emit(userdata)
  }

  assignScanLicence(user: any) {
    let dialogRef = this.modalService.open(
      AcceptRejectConfirmationModalComponent,
      {
        backdrop: 'static',
        size: 'md',
        keyboard: false,
        centered: true,
      }
    );
    if(dialogRef){
      dialogRef.componentInstance.companyActivated = 'Assign';
      dialogRef.componentInstance.message = `Do you want to Assign License, can't remove once assigned!`
      dialogRef.result.then(
        (result) => {
          if (result?.status) {
            this.assignCompanyLicenses(user?._id);
          }
        }
      );
    }
  }

  getAssignUserLicenses() {
    this.companyService.getAssignUserLicenses()?.subscribe({
      next: (res: any) => {
        this.userLicences = res.data.userIds || [];
      }, error: (error) => {
      }
    });
  }

  onChangeLicence(value: any){
    this.selectedLicence = value;
  }

  assignCompanyLicenses(userid: any) {
    this.loderIcon = true;
    this.companyService.assignUserLicense(userid)?.subscribe({
      next: (res: any) => {
        if (res['status'] == 'success') {
          this.message = 'Licence assigned successfully!';
          this.isToaster = true;
          this.loderIcon = false;
          this.userLicences.push(userid);
        }
      }, error: (error) => {
        this.message = error?.error?.message;
        this.isErrorToaster = true;
        this.loderIcon = false;
      }
    });
  }

  closeToaster(value: any) {
    this.isToaster = false;
    this.isErrorToaster = false;
  }
}
