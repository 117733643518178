<div  class="form-control form-select filterOffcanvas__inputlabel">
    <div (mouseleave)="showDropDown = false">
        <button class="drop-toggle btn flat" (click)="showDropdown()" [ngClass]="{ 'borderColor': borderColor }">
            <span class="spancolor" *ngIf="isMultiSelect && checkedMultiList.length === 0">
                Select {{placeholder}}
             </span>
             <span class="spancolor" *ngIf="isMultiSelect && checkedMultiList.length > 0">
                {{checkedMultiList.join(', ')}}
             </span>
             <span class="spancolor" *ngIf="!isMultiSelect && !previousSeletedValues ">
                Select {{placeholder}}
             </span>
             <span class="spancolor" *ngIf="!isMultiSelect && previousSeletedValues ">
                {{previousSeletedValues }}
             </span>
             
        </button>
        <div class="drop-show" *ngIf="showDropDown" (scroll)="onDropdownScroll($event)">
            <div class="usersFilter__branchesField">
                <div class="usersFilter__branchWrappar">
                    <img class="usersFilter__searchIcon"
                        src="../../../../assets/Images/user-management/searchIcon.svg" alt="search"  />
                    <input (input)="handleFilter($event)" class="usersFilter__input" [formControl]="searchControl"
                    placeholder="Search"/>
                </div>
            </div>
              <div *ngIf="showEmailIcon" class="createPackage__InviteUser"
              (click)="addItem(searchControl.value)">
              <img src="../../../../assets/Images/create-package/Addor.svg" alt="add" />
             Add External Email
            </div>
            <div *ngIf="isMultiSelect === true">
                <label *ngFor="let item of multiListData">
                    <app-checkbox checkboxStatus="{{item.checked}}"
                        (checked)="getMultiSelectedValue($event.event.target.checked, item)"></app-checkbox>
                    <span>{{item.name}}</span>
                </label>
            </div>
            <div *ngIf="isMultiSelect === false">
                <label *ngFor="let item of multiListData" (click)="singleSelectedValue(item)">
                    {{ item.name }}
                </label>
            </div>
        </div>
    </div>
</div>